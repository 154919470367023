import { useEffect, useState } from "react";

import { VIEW_MODES } from "./ViewMode.config";
import { ViewModeContext } from "./ViewMode.context";
import { matchMobileDevice } from "./ViewMode.utils";

export const ViewModeProvider = ({ userAgent, children }) => {
  const isMobile = matchMobileDevice(userAgent);
  const [value, setValue] = useState({
    mode: isMobile ? VIEW_MODES.MOBILE : VIEW_MODES.DESKTOP,
    isMobile,
    isDesktop: !isMobile,
  });

  useEffect(() => {
    const matcher = window.matchMedia("(max-width: 1024px)");

    const getViewModeState = () => {
      const viewMode = matcher.matches ? VIEW_MODES.MOBILE : VIEW_MODES.DESKTOP;

      setValue(() => ({
        mode: viewMode,
        isMobile: viewMode === VIEW_MODES.MOBILE,
        isDesktop: viewMode === VIEW_MODES.DESKTOP,
      }));
    };

    getViewModeState();
    matcher.addEventListener("change", getViewModeState);

    return () => {
      matcher.removeEventListener("change", getViewModeState);
    };
  }, []);

  return (
    <ViewModeContext.Provider value={value}>
      {children}
    </ViewModeContext.Provider>
  );
};
