import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

import { MenusContext } from "./Menus.context";
import { fetchMenusData } from "./Menus.utils";

export function MenusProvider({ children, value }) {
  const { locale } = useRouter();
  const { header, footer } = value;
  const [dataMenus, updateDataMenus] = useState({
    headerRaw: header,
    footerRaw: footer,
  });

  useEffect(() => {
    async function update() {
      const res = await fetchMenusData(locale);
      updateDataMenus((currentState) => ({
        ...currentState,
        headerRaw: res.data.header,
        footerRaw: res.data.footer,
      }));
    }
    void update();
  }, [locale]);

  const headerData = useMemo(
    () =>
      (dataMenus.headerRaw?.items ?? []).map(({ children, ...rest }) => ({
        ...rest,
        items: children,
      })),
    [dataMenus.headerRaw.items]
  );

  const footerData = useMemo(
    () =>
      (dataMenus.footerRaw?.items ?? []).map(({ children, ...rest }) => ({
        ...rest,
        items: children,
      })),
    [dataMenus.footerRaw.items]
  );

  const data = { headerData, footerData };
  return <MenusContext.Provider value={data}>{children}</MenusContext.Provider>;
}
