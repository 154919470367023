import Router from "next/router";
import Nprogress from "nprogress";
import { useEffect } from "react";

export const NavigationProgress = () => {
  useEffect(() => {
    Nprogress.configure({
      showSpinner: false,
      minimum: 0.3,
      easing: "ease",
      speed: 400,
    });

    Router.events.on("routeChangeStart", () => {
      Nprogress.start();
    });

    Router.events.on("routeChangeComplete", () => {
      Nprogress.done();
    });
  }, []);

  return null;
};
