export const phoneNumber = "0-800-301-404";

export const googleMapsApiKey = "";
export const googleMapsPinImage = { url: "" };

export const socialNetworks = [
  {
    icon: "telegram",
    title: "Our latest news in Telegram",
    href: "https://telegram.me/business_school_mim",
  },
  {
    icon: "instagram",
    title: "Follow us on Instagram",
    href: "https://www.instagram.com/mim_businessschool",
  },
  {
    icon: "facebook",
    title: "Connect us on Facebook",
    href: "https://www.facebook.com/mimkyiv",
  },
  {
    icon: "linkedin",
    title: "Connect us on Linkedin",
    href: "https://www.linkedin.com/school/mimkyiv",
  },
];

export const socialSharingLinks = [
  {
    icon: "telegram",
    title: "Our latest news in Telegram",
    href: "https://telegram.me/share/url?url=",
  },
  {
    icon: "facebook",
    title: "Connect us on Facebook",
    href: "https://www.facebook.com/sharer/sharer.php?u=",
  },
  {
    icon: "linkedin",
    title: "Connect us on Linkedin",
    href: "https://www.linkedin.com/share/url?url=",
  },
];
