export const logError = (e, context = {}) => {
  if (typeof window === "undefined") {
    console.log("SERVER ERROR! Context:", JSON.stringify(context));
  } else {
    console.log("CLIENT ERROR! Context:", JSON.stringify(context));
  }
  console.error(e);
};

export const nativeLogError = (...args) => {
  console.error(...args);
};
