import { fetchTranslations } from "features/translations";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";

export function TranslationsProvider({ children, value }) {
  const { locale, query } = useRouter();
  const [translations, updateTranslations] = useState(value);

  useEffect(() => {
    async function update() {
      const response = await fetchTranslations(locale);
      updateTranslations((currentState) => ({
        ...currentState,
        [locale]: response,
      }));
    }

    if (!translations.locale) {
      void update();
    }
  }, [translations.locale, locale]);

  const currentTranslations = translations?.[locale];

  const onError = useCallback(
    (e) => {
      if (query["_translationsDebug"] === "1") {
        if (e?.code === "MISSING_TRANSLATION") {
          console.log(
            `MISSED TRANSLATION: key "${e?.descriptor?.id}", locale "${locale}"`
          );
        }
      }
    },
    [locale, query]
  );

  return (
    <IntlProvider
      onError={onError}
      locale={locale}
      messages={currentTranslations}
    >
      {children}
    </IntlProvider>
  );
}
