export const GoogleTagNoscript = ({ googleId }) => {
  if (!googleId) {
    return;
  }
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${googleId}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
        id="tag-manager"
      ></iframe>
    </noscript>
  );
};
