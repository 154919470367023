import { logError } from "features/logger";

export async function fetchTranslations(locale) {
  const isBrowser = typeof window !== "undefined";
  try {
    const res = await fetch(
      `${
        isBrowser ? "" : process.env.NEXT_PUBLIC_CMS_API_URL
      }/api/translations/${locale}`
    );
    return await res.json();
  } catch (e) {
    logError(e, {
      scope: "fetchTranslations",
      collection: "translations",
      locale,
      isBrowser,
    });

    return await Promise.resolve({ error: "There" });
  }
}
