import { fetchAPI } from "features/cms";
import { logError } from "features/logger";

import {
  googleMapsApiKey,
  googleMapsPinImage,
  phoneNumber,
  socialNetworks,
  socialSharingLinks,
} from "./Global.config";

const defaultValue = {};

export const fetchGlobalData = async (locale) => {
  try {
    const res = await fetchAPI(
      "/common",
      {
        populate: "*",
      },
      locale
    );

    const currentData = res?.data ?? {};

    return Promise.resolve({
      data: {
        faviconUrl: currentData.favicon?.url,
        faviconExt: currentData.favicon?.ext,
        logoHeader: currentData.logoHeader,
        logoFooter: currentData.logoFooter,
        phoneNumber: currentData?.phoneNumber ?? phoneNumber,
        socialNetworks: currentData?.socialIcons ?? socialNetworks,
        socialSharingLinks:
          currentData?.socialSharingLink ?? socialSharingLinks,
        googleMapsApiKey: currentData?.googleMapsApiKey ?? googleMapsApiKey,
        googleMapsPinImage:
          currentData?.googleMapsPinImage ?? googleMapsPinImage,
        googleTagManagerId: currentData.googleTagManagerId ?? "",
      },
      error: null,
    });
  } catch (e) {
    logError(e, {
      scope: "fetchGlobalData",
      collection: "common",
      locale,
    });

    return Promise.resolve({
      data: defaultValue,
      error: {
        status: e.status,
      },
    });
  }
};
