import { useIntl } from "react-intl";

/**
 * How to format messages - https://formatjs.io/docs/getting-started/message-declaration#using-imperative-api-intlformatmessage
 * @returns {{t: (function(*, *=): string)}}
 */

export function useTranslation() {
  const intl = useIntl();

  const t = (key, values = {}) =>
    intl.formatMessage(
      {
        id: key,
        defaultMessage: key,
      },
      values
    );

  return { t };
}
