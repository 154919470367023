import qs from "qs";

import { getCmsUrl } from "./getCmsUrl";

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified/ check (populate), if there are any performance problems
 * @param {Object} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function fetchAPI(
  path,
  urlParamsObject = {},
  locale,
  options = {}
) {
  // Merge default and user options
  const mergedOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    ...options,
  };

  // Build request URL
  const queryString = qs.stringify(urlParamsObject);
  const urlParams = [locale ? `locale=${locale}` : "", queryString]
    .filter(Boolean)
    .join("&");

  const requestUrl = `${getCmsUrl(`/api${path}?${urlParams}`)}`;

  // Trigger API call
  const response = await fetch(requestUrl, mergedOptions);

  // Handle response
  if (!response.ok) {
    return Promise.reject(response);
  }

  return await response.json();
}
