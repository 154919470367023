import { createContext } from "react";

import { VIEW_MODES } from "./ViewMode.config";

export const defaultValue = {
  mode: VIEW_MODES.DESKTOP,
  isMobile: false,
  isDesktop: true,
};

export const ViewModeContext = createContext(defaultValue);
