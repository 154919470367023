import Head from "next/head";

const extRelMapping = {
  ".png": "icon",
  ".svg": "icon",
  ".ico": "shortcut icon",
};

export const Favicon = ({ url, ext }) => {
  if (url && ext) {
    const rel = extRelMapping?.[ext] ?? extRelMapping[".ico"];
    return (
      <Head>
        <link type="image/x-icon" rel={rel} href={url} />
      </Head>
    );
  }

  return (
    <Head>
      <link type="image/x-icon" rel="icon" href="/favicon.png" />
    </Head>
  );
};
