import Head from "next/head";

const defaultData = {};
export const Seo = ({ data = defaultData }) => {
  const title = data.title ?? "";
  const description = data.description ?? "";
  const openGraphImage = data.openGraphImage?.url ?? "";
  const twitterImage = data.twitterImage?.url ?? "";

  return (
    <Head>
      {title && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta property="og:image:alt" content={title} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:image:alt" content={title} />
        </>
      )}
      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      )}
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      {openGraphImage && <meta property="og:image" content={openGraphImage} />}
      {twitterImage && <meta name="twitter:image" content={twitterImage} />}
    </Head>
  );
};
