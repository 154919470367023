import { fetchAPI } from "features/cms";
import { logError } from "features/logger";

const defaultValue = {
  header: {},
  footer: {},
};

export const fetchMenusData = async (locale) => {
  try {
    const res = await fetchAPI("/menus", {
      populate: "*",
      filters: {
        slug: {
          $in: [`header-${locale}`, `footer-${locale}`],
        },
      },
      nested: true,
    });

    const currentHeader =
      res?.data?.find(({ slug }) => slug === `header-${locale}`) ?? {};

    const currentFooter =
      res?.data?.find(({ slug }) => slug === `footer-${locale}`) ?? {};

    return Promise.resolve({
      data: {
        header: currentHeader,
        footer: currentFooter,
      },
      error: null,
    });
  } catch (e) {
    logError(e, {
      scope: "fetchMenusData",
      collection: "menus",
      locale,
    });
    return Promise.resolve({
      data: defaultValue,
      error: {
        status: e.status,
      },
    });
  }
};
